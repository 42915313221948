<template>
  <div id="hot_list">
    <img src="@/assets/images/rxbd_banner.png" width="100%" />
    <main>
      <van-tabs
        title-active-color="#F91C12"
        title-inactive-color="#fff"
        :ellipsis="false"
        @change="hotListTabChange"
        v-model="active"
      >
        <van-tab
          v-for="list in hotList"
          :title="list.categoryName"
          :key="list.categoryId"
        >
          <div
            class="goods"
            v-for="(item, i) in list.goodsList"
            :key="item.commId"
          >
            <div
              class="ranking"
              style="width: 39px; height: 47px"
              :style="
                i === 0
                  ? ' background-position:-10px -10px'
                  : i === 1
                  ? 'background-position: -69px -10px;'
                  : i === 2
                  ? 'background-position: -128px -10px;'
                  : 'background-position: -187px -10px;'
              "
            >
              <p>TOP</p>
              <p>{{ item.ranking }}</p>
            </div>
            <div class="goods_img">
              <img v-lazy="item.filePath" />
              <span class="new_product" v-if="item.newProducts">新品</span>
              <span class="seckill" v-if="item.querySecKillCommodityListRsp"
                >秒杀</span
              >
            </div>
            <div class="details">
              <div class="top">
                <b class="name">{{ item.commodityName }}</b>
                <span>{{ item.specsParameter }}</span>
              </div>
              <div class="bottom">
                <p class="price"><span>¥</span>{{ item.price }}</p>
                <van-button
                  :to="`/goodsDetails?commId=${item.commId}`"
                  round
                  type="info"
                  color="linear-gradient(180deg, #F91C12 0%, #FB4E10 100%)"
                  >加购</van-button
                >
              </div>
            </div>
          </div>
          <van-empty description="暂无商品" v-show="!list.goodsList.length" />
          <p class="next" v-show="active + 1 !== hotList.length" @click="next">
            查看下一榜单
          </p>
        </van-tab>
      </van-tabs>
    </main>
    <functionButton />
  </div>
</template>

<script>
import { preferential } from "@/utils/validate";
import { mapState } from "vuex";
export default {
  name: "hot-list",

  data() {
    return {
      active: 0,
      hotList: [],
    };
  },
  computed: {
    ...mapState("user", ["userType", "userInfo"]),
  },
  created() {
    this.getHotSaleActivityCategoryList();
  },
  methods: {
    async getHotSaleActivityCategoryList() {
      const data = await this.$API.hotList.HotSaleActivityCategoryList({
        object: {},
      });

      this.hotList = data.data.data.map((e) => {
        return {
          categoryId: e.categoryId,
          categoryName: e.categoryName,
          goodsList: [],
        };
      });
      let i = this.hotList.findIndex((i) => {
        return `${i.categoryId}` === this.$route.query.categoryId;
      });
      if (i >= 0) this.active = i;

      this.getHotSellingList(this.hotList[this.active].categoryId);
    },
    async getHotSellingList(categoryId) {
      this.Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });

      await this.$API.hotList
        .HotSaleActivityList({
          object: {
            categoryId: categoryId,
            userId: this.userInfo ? this.userInfo.id : undefined,
          },
        })
        .then((res) => {
          if (!res.data.data.length) throw new Error("");
          res.data.data.forEach((goods) => {
            let img = goods.filePath.split(",");
            if (img[0].endsWith(".mp4")) {
              goods.filePath = img[1];
            } else {
              goods.filePath = img[0];
            }

            if (
              goods.newUser &&
              goods.storeTastingActivityInfoEntity &&
              goods.querySecKillCommodityListRsp
            ) {
              if (
                goods.storeTastingActivityInfoEntity.price <=
                goods.querySecKillCommodityListRsp.retailSeckillPrice
              ) {
                goods.price =
                  goods.storeTastingActivityInfoEntity.price.toFixed(2);
              } else {
                goods.price =
                  goods.querySecKillCommodityListRsp.retailSeckillPrice.toFixed(
                    2
                  );
              }
            } else if (goods.newUser && goods.storeTastingActivityInfoEntity) {
              goods.price =
                goods.storeTastingActivityInfoEntity.price.toFixed(2);
            } else if (goods.querySecKillCommodityListRsp) {
              goods.price =
                goods.querySecKillCommodityListRsp.retailSeckillPrice.toFixed(
                  2
                );
            } else if (this.userType === "PURCHASE") {
              goods.price = preferential(goods, "tradePrice").price;
            } else {
              goods.price = preferential(goods, "retailPrice").price;
            }
          });
          this.hotList[this.active].goodsList = res.data.data;
          this.Toast.clear();
        })
        .catch((e) => {
          console.log(e);
          this.Toast.clear();
        });
    },
    hotListTabChange(i) {
      if (!this.hotList[i].goodsList.length) {
        this.getHotSellingList(this.hotList[i].categoryId);
      }
    },
    next() {
      this.active++;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      window.pageXOffset = 0;
      this.hotListTabChange(this.active);
    },
  },
};
</script>

<style lang="scss" scoped>
#hot_list {
  display: flex;
  flex-direction: column;
  height: 100%;
  main {
    flex: 1;
    background: #f84402;
    ::v-deep .van-tabs__wrap {
      height: auto;
      .van-tabs__nav {
        background-image: url("../../assets/images/rx_bg.png");
        padding: 24px 10px 16px 0;
        .van-tab {
          background: linear-gradient(180deg, #ff632c 0%, #ec0f05 100%);
          padding: 5px 12px;
          border-radius: 15px;
          margin-left: 10px;
          min-width: 80px;
          flex: none;
        }
        .van-tab--active {
          background: #fff;
        }
        .van-tabs__line {
          display: none;
        }
      }
    }
    ::v-deep .van-tabs__content {
      .van-tab__pane {
        display: flex;
        flex-direction: column;
        // align-items: center;
        padding: 15px;
        padding-top: 0;
        .goods {
          width: 100%;
          background: #fff;
          border-radius: 10px;
          margin-bottom: 10px;
          padding: 10px;
          display: flex;
          position: relative;
          .ranking {
            position: absolute;
            top: 0;
            width: 39px;
            height: 47px;
            background: url("../../assets/images/css_sprites.png");
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: bold;
            color: #f5f6fa;
            padding-top: 4px;
            font-style: italic;
            // background-attachment: fixed;
            background-repeat: no-repeat;
            z-index: 1;
          }
          .goods_img {
            width: 132px;
            height: 132px;
            margin-right: 12px;
            position: relative;
            img {
              width: 100%;
              height: 100%;

              object-fit: contain;
            }
            .new_product {
              position: absolute;
              bottom: 0;
              right: 0;
              background: linear-gradient(180deg, #2d87ff 0%, #0f63d5 100%);
              border-radius: 2px;
              font-size: 12px;
              color: #fff;
              padding: 4px 7px;
            }
            .seckill {
              position: absolute;
              right: 0;
              top: 0;
              background: url("../../assets/images/home_ms_bg.png") no-repeat
                100% 100%;
              background-size: 100%;
              font-size: 12px;
              color: #ffffff;
              line-height: 17px;
              padding: 20px 8px 13px;
            }
          }
          .details {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .top {
              b {
                color: #333333;
                line-height: 15px;
                display: block;
                margin-bottom: 8px;
              }
              span {
                font-size: 12px;
                color: #999999;
              }
            }
            .bottom {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
              .price {
                font-size: 18px;
                font-weight: 800;
                color: #f91c12;
                span {
                  font-size: 12px;
                  font-weight: normal;
                }
              }
              .van-button {
                padding: 0 18px;
                height: 34px;
              }
            }
          }
        }

        .next {
          text-align: center;
          color: #fff;
          padding-top: 5px;
        }
        .van-empty__description {
          color: #fff;
        }
      }
    }
  }
}
// rx_bg.png
</style>
